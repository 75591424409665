import React from "react"
import styled from "styled-components"
import "./style.css"
import { Bold, ContentP, Mail } from "../PrivacySection/PrivacyStyle"

const OrderedLine = ({ children, type, mtop }) => {
  return (
    <ol
      className={`custom-ol ${mtop && "custom-ol-mtop"}`}
      type={type}
      style={{
        paddingInlineStart: 0,
        fontFamily: "Typefez-reg",
      }}
    >
      {children}
    </ol>
  )
}

const CustomLi = ({ children }) => {
  return <ContentLi className="custom-li">{children}</ContentLi>
}
const CustomLiBig = ({ children }) => {
  return <MainPointLi className="custom-li-big">{children}</MainPointLi>
}

const CustomNoNumber = ({ children }) => {
  return <ContentP fsize="16px">{children}</ContentP>
}

export const TermAndCondition = ({ content }) => {
  const {
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
    Version,
  } = content || {}
  return (
    <div>
      <OrderedLine>
        <CustomLiBig>
          {Article1.Title}
          <OrderedLine mtop>
            <CustomLi>{Article1.Point1a}</CustomLi>
            <CustomLi>
              {Article1.Point2a}
              <Mail
                href="https://doku.promo/syarat-dan-ketentuan"
                target="_blank"
              >
                <span style={{ textDecoration: "underline" }}>
                  {Article1.Point2b}
                </span>
              </Mail>
              {Article1.Point2c}
            </CustomLi>
            <CustomLi>{Article1.Point3a}</CustomLi>
            <CustomLi>{Article1.Point4a}</CustomLi>
            <CustomLi>{Article1.Point5a}</CustomLi>
          </OrderedLine>
        </CustomLiBig>

        <CustomLiBig>
          {Article2.Title}
          <div style={{ marginTop: 20 }}>
            <CustomNoNumber>
              <Bold>{Article2.Point1a}</Bold>
              {Article2.Point1b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point2a}</Bold>
              {Article2.Point2b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point3a}</Bold>
              {Article2.Point3b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point4a}</Bold>
              {Article2.Point4b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point5a}</Bold>
              {Article2.Point5b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point6a}</Bold>
              {Article2.Point6b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point7a}</Bold>
              {Article2.Point7b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point8a}</Bold>
              {Article2.Point8b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point9a}</Bold>
              {Article2.Point9b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point10a}</Bold>
              {Article2.Point10b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point11a}</Bold>
              {Article2.Point11b}
            </CustomNoNumber>
            <CustomNoNumber>
              <Bold>{Article2.Point12a}</Bold>
              {Article2.Point12b}
            </CustomNoNumber>
          </div>
        </CustomLiBig>

        <CustomLiBig>
          {Article3.Title}
          <OrderedLine mtop>
            <CustomLi>{Article3.Point1a}</CustomLi>
            <CustomLi>{Article3.Point2a}</CustomLi>
            <CustomLi>{Article3.Point3a}</CustomLi>
            <CustomLi>{Article3.Point4a}</CustomLi>
            <CustomLi>{Article3.Point5a}</CustomLi>
            <CustomLi>{Article3.Point6a}</CustomLi>
            <CustomLi>{Article3.Point7a}</CustomLi>
            <CustomLi>{Article3.Point8a}</CustomLi>
          </OrderedLine>
        </CustomLiBig>

        <CustomLiBig>
          {Article4.Title}
          <OrderedLine mtop>
            <CustomLi>{Article4.Point1a}</CustomLi>
            <CustomLi>{Article4.Point2a}</CustomLi>
            <CustomLi>{Article4.Point3a}</CustomLi>
            <CustomLi>{Article4.Point4a}</CustomLi>
            <CustomLi>{Article4.Point5a}</CustomLi>
            <CustomLi>{Article4.Point6a}</CustomLi>
            <CustomLi>{Article4.Point7a}</CustomLi>
            <CustomLi>{Article4.Point8a}</CustomLi>
            <CustomLi>{Article4.Point9a}</CustomLi>
            <CustomLi>{Article4.Point10a}</CustomLi>
            <CustomLi>{Article4.Point11a}</CustomLi>
            <CustomLi>{Article4.Point12a}</CustomLi>
            <CustomLi>{Article4.Point13a}</CustomLi>
            <CustomLi>{Article4.Point14a}</CustomLi>
          </OrderedLine>
        </CustomLiBig>

        <CustomLiBig>
          {Article5.Title}
          <OrderedLine mtop>
            <CustomLi>{Article5.Point1a}</CustomLi>
            <CustomLi>{Article5.Point2a}</CustomLi>
            <CustomLi>{Article5.Point3a}</CustomLi>
          </OrderedLine>
        </CustomLiBig>

        <CustomLiBig>
          {Article6.Title}
          <OrderedLine mtop>
            <CustomNoNumber>{Article6.Point1a}</CustomNoNumber>
          </OrderedLine>
        </CustomLiBig>

        <CustomLiBig>
          {Article7.Title}
          <OrderedLine mtop>
            <CustomLi>{Article7.Point1a}</CustomLi>
            <CustomLi>{Article7.Point2a}</CustomLi>
            <CustomLi>{Article7.Point3a}</CustomLi>
            <CustomLi>{Article7.Point4a}</CustomLi>
            <CustomLi>{Article7.Point5a}</CustomLi>
            <CustomLi>{Article7.Point6a}</CustomLi>
          </OrderedLine>
        </CustomLiBig>
      </OrderedLine>

      <ContentP pleft="30px" margin="50px 0px 50px 0px">
        {Version.Title}
      </ContentP>
    </div>
  )
}

const ContentLi = styled.li`
  margin-bottom: 20px;
  font-size: 16px;
  text-align: justify;
  font-family: "Typefez-reg";
`

// const ContentP = styled.p`
//   margin-bottom: 20px;
//   font-size: 16px;
//   text-align: justify;
//   font-family: "Typefez-reg";
// `

const MainPointLi = styled.li`
  margin-bottom: 20px !important;
  font-size: 24px;
  font-family: "Typefez-bold";
  color: #196e97;
`
