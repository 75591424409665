import React from "react"
import "../components/layout.css"
import Seo from "../components/SEOComponents/Seo"

import { NavigationBar } from "../components/NavigationBar/parent"
import Footer from "../components/FooterComponents/Footer"

import { MainPageJson, ServiceJson, PathJson, EwalletTncJson } from "../components/location"
import { LayoutTextPage } from "../components/LayoutTextPage/parent"
import { TermAndCondition } from "../components/Ewallet/TermAndCondition"

function EWalletTermAndCondition() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const EwalletContent = EwalletTncJson()
  const { Title, Title1 } = EwalletContent.Header || {}

  return (
    <div className="maxwidth">
      <Seo title="service-agreement" />
      <NavigationBar />
      <LayoutTextPage title={Title} secondTitle={Title1}>
        <TermAndCondition content={EwalletContent} propsLink={PathContent} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default EWalletTermAndCondition
